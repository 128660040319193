import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Utils } from "Helpers/Scripts/Utils";
import { ITranslationGroupDto } from "Kendo/Scripts/ITranslationGroupDto";
import { ITranslationsConfigDto } from "Kendo/Scripts/ITranslationsConfigDto";

/**
 * Client-side Kendo widget translations setter.
 * Applies various widget & other translations via extending the described
 * client-side script namespaces depending on data previously serialized in HTML markup.
 * Only data which differ from default built-in EN labels should be received from server.
 */
@OnlyInstantiableByContainer
@Singleton
export class KendoTranslationsSetter {
    public get key(): string {
        return "KendoTranslationsSetter";
    }

    private static readonly TRANSLATION_SETTINGS_KEY: string = "translation";
    private static readonly DEFAULT_PROPERTY_TO_EXTEND: string = "prototype.options.messages";
    private readonly _logger: ILogger;

    private _defaultPropertyToExtend: string;

    constructor(@Inject loggerFactory: LoggerFactory) {
        this._logger = loggerFactory.getLogger(this.key);
    }

    public init(): this {
        const data: ITranslationsConfigDto = Utils.getOptions(
            KendoTranslationsSetter.TRANSLATION_SETTINGS_KEY
        );
        if (!data || !data.translationGroups || !data.translationGroups.length) {
            this._logger.info("No translations were received from server.");
            return this;
        }

        this._defaultPropertyToExtend =
            data.defaultExtend || KendoTranslationsSetter.DEFAULT_PROPERTY_TO_EXTEND;
        data.translationGroups.forEach((translationGroup) =>
            this._applyTranslationsToNamespace(translationGroup)
        );
        this._logger.info("Kendo widget translations were successfully applied.");

        return this;
    }

    private _applyTranslationsToNamespace({ ns, extend, tr }: ITranslationGroupDto): void {
        const prop = extend || this._defaultPropertyToExtend;
        // execute the updating function in global context
        let result: object | undefined;
        // evaluate the namespace itself
        let functionTemplate = `return ${ns};`;
        try {
            result = new Function(functionTemplate)();
        } catch (error) {
            // no code
        }
        if (!result) {
            this._logger.info(`Namespace "${ns}" doesn't exist.`);
            return;
        }

        // when the namespace exists, evaluate the property to be extended
        functionTemplate = `return ${ns}.${prop};`;
        let errorMessage = "";
        try {
            result = new Function(functionTemplate)();
        } catch (error) {
            errorMessage = error;
        }
        if (!result) {
            this._logger.error(
                `Error evaluating namespace "${ns}.${prop}" for extension with translations:\n\t${errorMessage}`
            );
            return;
        }

        // startup checking done, now try to extend the found object
        // we need the translations serialized back as code
        const translations = JSON.stringify(Utils.extend(true, result, tr));
        functionTemplate = `${ns}.${prop} = ${translations};`;
        try {
            new Function(functionTemplate)();
        } catch (error) {
            this._logger.error(
                `Error extending namespace "${ns}.${prop}" with translations:\n\t${error}`
            );
            return;
        }
        this._logger.info(
            `Namespace "${ns}.${prop}" was extended with following translations:`,
            translations
        );
    }
}
