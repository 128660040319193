import { Inject, OnlyInstantiableByContainer, Singleton } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Utils } from "Helpers/Scripts/Utils";

/**
 * Client-side Kendo culture setter.
 * Calls Kendo culture & other additional settings depending on data
 * previously serialized in HTML markup.
 */
@OnlyInstantiableByContainer
@Singleton
export class KendoCultureSetter {
    public get key(): string {
        return "KendoCultureSetter";
    }

    private static readonly CULTURE_SETTINGS_KEY: string = "culture";
    private readonly _logger: ILogger;

    constructor(@Inject loggerFactory: LoggerFactory) {
        this._logger = loggerFactory.getLogger(this.key);
    }

    public init(): this {
        const cultureData = Utils.getOptions(KendoCultureSetter.CULTURE_SETTINGS_KEY);
        if (!cultureData || !cultureData.name) {
            this._logger.warning(
                "Couldn't read Kendo culture settings data or the data is corrupted:",
                cultureData
            );
            return this;
        }

        const currentCulture = cultureData.name;
        kendo.cultures[currentCulture] = cultureData;
        kendo.culture(currentCulture);
        this._updateIndianCulture();
        this._logger.info(
            "Kendo culture settings were successfully applied. Current culture:",
            currentCulture
        );

        return this;
    }

    /**
     * Updates group sizes in number format for en_IN culture as there is supposedly a bug in the default
     * culture definition.
     *
     * TODO: Updates the default culture settings (numberFormat, numberFormat.currency, numberFormat.percent),
     * not the oriCurrency, is this OK? Was this needed at all anytime to solve it like this? Isn't all
     * needed data already available in Sitecore?
     * Investigate more when there is time for this.
     *
     * Comment from original fix implementation:
     * TODO: there is issue with wrong number format for kendo culture en-IN
     *       this issue causes comma before the number therefore we changed format to 11,11,11,22,333.00
     *       if number format has to be like 111111,22,333.00 is needed to change groupSize to [3, 2, 20]
     *       related Bug 149919:PROD: The comma before the number in KPI bar - Order dashboard
     *       after fix from kendo side, we can remove this code
     */
    private _updateIndianCulture(): void {
        Object.keys(kendo.cultures).forEach((cultureName) => {
            const culture = kendo.cultures[cultureName];
            if (!culture || culture.name !== "en-IN" || !culture.numberFormat) {
                return;
            }

            this._logger.info("Found en-IN Kendo culture.");
            // eslint-disable-next-line no-magic-numbers
            const GROUP_SIZE = [3, 2];
            if (JSON.stringify(culture.numberFormat.groupSize) === "[3,2,0]") {
                culture.numberFormat.groupSize = GROUP_SIZE;
                this._logger.info(
                    "Group size settings for en-IN Kendo culture were updated (numberFormat.groupSize)."
                );
            }
            if (JSON.stringify(culture.numberFormat.currency.groupSize) === "[3,2,0]") {
                culture.numberFormat.currency.groupSize = GROUP_SIZE;
                this._logger.info(
                    "Group size settings for en-IN Kendo culture were updated (numberFormat.currency.groupSize)."
                );
            }
            if (JSON.stringify(culture.numberFormat.percent.groupSize) === "[3,2,0]") {
                culture.numberFormat.percent.groupSize = GROUP_SIZE;
                this._logger.info(
                    "Group size settings for en-IN Kendo culture were updated (numberFormat.percent.groupSize)."
                );
            }
        });
    }
}
