﻿(function () {
    var kendo = window.kendo,
        ui = kendo.ui,
        Widget = ui.Widget;

    var OriDialog = Widget.extend({

        init: function (element, options) {
            var that = this;

            kendo.ui.Widget.fn.init.call(that, element, options);

            that.refresh();
        },

        options: {
            name: "OriDialog",
            autoBind: true,
            templateSelector: "kendo-template-dialog",
            title: "",
            message: "",
            icon: null,
            onOkCallback: null,
            onCancelCallback: null,
            onYesCallback: null,
            onNoCallback: null,
            isOkVisible: true,
            isCancelVisible: true,
            isYesVisible: false,
            isNoVisible: false,
            instanceName: null,
            onCloseCallback: null,
            resizable: true,
            draggable: true
        },

        refresh: function () {
            var that = this,
                ns = ".ori.dialog",
                dialogClass = "ori-dialog",
                random = ori.generateRandomString(5, "abcdefghijklmnopqrstuvwxyz"),
                dialogName = that.options.instanceName || "{0}-{1}".formatWith(that.options.name, random),
                template = kendo.template($("#" + that.options.templateSelector).html()),
                dialogId = "#{0}".formatWith(dialogName),
                data = this.options,
                content = template(data);

            if (that.options.instanceName) {
                if ($(dialogId).length) {
                    return;
                }
            }

            $('<div id="{0}"></div>'.formatWith(dialogName)).kendoWindow({
                modal: true,
                resizable: that.options.resizable,
                draggable: that.options.draggable,
                title: that.options.title,
                deactivate: function () {
                    this.destroy();
                },
                close: that.options.onCloseCallback
            }).data("kendoWindow")
                .content(content)
                .center()
                .open()
                .wrapper.addClass(dialogClass);

            $(dialogId + " .js-dialog-okbutton").on("click" + ns, function () {
                $(dialogId).data("kendoWindow").close();
                if (that.options.onOkCallback != null) {
                    that.options.onOkCallback();
                }
            });

            $(dialogId + " .js-dialog-cancelbutton").on("click" + ns, function () {
                $(dialogId).data("kendoWindow").close();
                if (that.options.onCancelCallback != null) {
                    that.options.onCancelCallback();
                }
            });

            $(dialogId + " .js-dialog-yesbutton").on("click" + ns, function () {
                $(dialogId).data("kendoWindow").close();
                if (that.options.onYesCallback != null) {
                    that.options.onYesCallback();
                }
            });

            $(dialogId + " .js-dialog-nobutton").on("click" + ns, function () {
                $(dialogId).data("kendoWindow").close();
                if (that.options.onNoCallback != null) {
                    that.options.onNoCallback();
                }
            });
        }
    });

    ui.plugin(OriDialog);

})(jQuery);
