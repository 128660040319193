// core
import "@progress/kendo-ui/js/kendo.core";
import "@progress/kendo-ui/js/kendo.aspnetmvc";
import "@ori/kendo-ui-extensions/js/ori.kendo.core";
import "@ori/kendo-ui-extensions/css/ori.nova.css";
import "@ori/kendo-ui-extensions/css/kendo.rtl.css";
// basic generic functionality
import "@progress/kendo-ui/js/kendo.data";
import "@progress/kendo-ui/js/kendo.data.odata";
import "@progress/kendo-ui/js/kendo.data.xml";
import "@progress/kendo-ui/js/kendo.userevents";
import "@progress/kendo-ui/js/kendo.fx";
import "@progress/kendo-ui/js/kendo.draganddrop";
import "@progress/kendo-ui/js/kendo.mobile.scroller";
import "@progress/kendo-ui/js/kendo.binder";
import "@progress/kendo-ui/js/kendo.resizable";
import "@progress/kendo-ui/js/kendo.sortable";
import "@progress/kendo-ui/js/kendo.columnsorter";
import "@progress/kendo-ui/js/kendo.columnmenu";
import "@progress/kendo-ui/js/kendo.filtermenu";
import "@progress/kendo-ui/js/kendo.filtercell";
import "@progress/kendo-ui/js/kendo.groupable";
import "@progress/kendo-ui/js/kendo.editable";
import "@progress/kendo-ui/js/kendo.selectable";
import "@progress/kendo-ui/js/kendo.reorderable";
import "@progress/kendo-ui/js/kendo.view";
// popups, tooltips
import "@progress/kendo-ui/js/kendo.popup";
import "@ori/kendo-ui-extensions/js/ori.kendo.popup";
import "@progress/kendo-ui/js/kendo.tooltip";
import "@ori/kendo-ui-extensions/js/ori.kendo.tooltip";
// data lists
import "@progress/kendo-ui/js/kendo.list";
import "@ori/kendo-ui-extensions/js/ori.kendo.list";
import "@progress/kendo-ui/js/kendo.virtuallist";
// basic widgets
import "@progress/kendo-ui/js/kendo.button";
import "@ori/kendo-ui-extensions/js/ori.kendo.button";
import "@ori/kendo-ui-extensions/js/ori.kendo.textbox";
import "@ori/kendo-ui-extensions/js/ori.kendo.textarea";
import "@ori/kendo-ui-extensions/js/ori.kendo.password";
import "@progress/kendo-ui/js/kendo.autocomplete";
import "@ori/kendo-ui-extensions/js/ori.kendo.autocomplete";
import "@progress/kendo-ui/js/kendo.numerictextbox";
import "@ori/kendo-ui-extensions/js/ori.kendo.numerictextbox";
import "@progress/kendo-ui/js/kendo.calendar";
import "@ori/kendo-ui-extensions/js/ori.kendo.calendar";
import "@progress/kendo-ui/js/kendo.dropdownlist";
import "@ori/kendo-ui-extensions/js/ori.kendo.dropdownlist";
import "@progress/kendo-ui/js/kendo.combobox";
import "@ori/kendo-ui-extensions/js/ori.kendo.combobox";
import "@progress/kendo-ui/js/kendo.datepicker";
import "@progress/kendo-ui/js/kendo.timepicker";
import "@progress/kendo-ui/js/kendo.datetimepicker";
import "@ori/kendo-ui-extensions/js/ori.kendo.datepickers";
import "@ori/kendo-ui-extensions/js/ori.kendo.dropdowndatepicker";
import "@ori/kendo-ui-extensions/js/ori.kendo.checkbox";
import "@ori/kendo-ui-extensions/js/ori.kendo.radiobutton";
import "@ori/kendo-ui-extensions/css/kendo.checkboxradio.css";
import "@progress/kendo-ui/js/kendo.multiselect";
import "@ori/kendo-ui-extensions/js/ori.kendo.multiselect";
import "@progress/kendo-ui/js/kendo.progressbar";
import "@progress/kendo-ui/js/kendo.panelbar";
import "@ori/kendo-ui-extensions/js/ori.kendo.panelbar";
import "@ori/kendo-ui-extensions/css/kendo.panelbar.css";
// dialog widgets; TODO: convert Client/Scripts/dialog usages to Kendo window or dialog
import "@progress/kendo-ui/js/kendo.window";
import "@ori/kendo-ui-extensions/js/ori.kendo.window";
import "@ori/kendo-ui-extensions/css/kendo.window.css";
import "@progress/kendo-ui/js/kendo.dialog";
import "@ori/kendo-ui-extensions/js/ori.kendo.dialog";
import "./dialog";
import "@ori/kendo-ui-extensions/css/kendo.dialog.css";
// validations extension
import "@ori/kendo-ui-extensions/js/ori.kendo.error.highlighting";
import "@ori/kendo-ui-extensions/js/ori.kendo.validator";
// Kendo culture & other settings setup
import { Container } from "typescript-ioc";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { KendoCultureSetter } from "Kendo/Scripts/KendoCultureSetter";
import { KendoTranslationsSetter } from "Kendo/Scripts/KendoTranslationsSetter";

// instantiate
const cultureSetter = Container.get(KendoCultureSetter);
const translationsSetter = Container.get(KendoTranslationsSetter);
// init from markup data on DOM ready
Container.get(ReadyHelper).bindDomReady(() => {
    cultureSetter.init();
    translationsSetter.init();
});
